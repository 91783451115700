import ApiBase from 'modules/apis/apiBase';
import type { IResponseData } from 'modules/apis/Response';

export interface ApiLoginResponse {
    token: ApiTokenResponse;
    email: string;
    firstName?: string;
    lastName?: string;
    fullName?: string;
    phoneNumber?: string;
}

export interface ApiTokenResponse {
    accessToken: string;
    refreshToken: string;
}

export interface ApiLoginSchema {
    email: string;
    password: string;
    deviceType?: ApiDeviceType;
    deviceToken?: string;
    deviceModel?: string;
    deviceId?: string;
}

export type ApiDeviceType = 'ANDROID' | 'IOS' | 'WEBAPP';

export interface ApiUserResponse {
    hasCreatedDatabase?: boolean;
    email: string;
    fullName?: string;
    phoneNumber?: string;
}

export interface ApiUpdateMeSchema {
    fullName?: string;
    phoneNumber?: string;
}

export interface ApiRegisterUserSchema {
    email: string;
    password: string;
    fullName: string;
}

export interface ApiChangePasswordSchema {
    oldPassword: string;
    newPassword: string;
}

export interface ApiResetPasswordSchema {
    email: string;
    token: string;
    newPassword: string;
}
export interface ApiLoginWithMicrosoftSchema {
    token: string;
    oid: string;
    email?: string;
}
export interface ApiLoginWithMicrosoftResponse {
    token: Token;
    email: string;
    first_name: string;
    last_name: string;
    full_name: string;
    phone_number: string;
}

export interface ApiLoginWithGoogleSchema {
    token: string;
    email: string;
}
export interface ApiLoginWithGoogleResponse {
    token: Token;
    email: string;
    first_name: string;
    last_name: string;
    full_name: string;
    phone_number: string;
}

export interface ApiCheckSocialAccountSchema {
    id: string;
    provider_type: string;
}

export interface Token {
    accessToken: string;
    refreshToken: string;
}

export interface ApiValidatePasswordResponse {
    passwordLengthMin: number;
    containNumber: boolean;
    containUppercaseCharacters: boolean;
    containSpecialCharacters: boolean;
    specialCharacters: string;
}

class AuthenticateService extends ApiBase {
    login = (body: ApiLoginSchema) => {
        return this.post(`/oauth/login`, body) as Promise<
            IResponseData<ApiLoginResponse>
        >;
    };

    getMe = () => {
        return this.get(`/oauth/detail/me`) as Promise<
            IResponseData<ApiUserResponse>
        >;
    };

    updateMe = (body: ApiUpdateMeSchema) => {
        return this.put(`/oauth/update/me`, body) as Promise<
            IResponseData<ApiUserResponse>
        >;
    };

    createUser = (body: ApiRegisterUserSchema) => {
        return this.post(`/oauth/register`, body) as Promise<
            IResponseData<boolean>
        >;
    };

    changePassword = (body: ApiChangePasswordSchema) => {
        return this.put(`/oauth/change-password`, body) as Promise<
            IResponseData<boolean>
        >;
    };

    forgotPassword = (email: string) => {
        return this.post(`/oauth/forgot-password/${email}`) as Promise<
            IResponseData<boolean>
        >;
    };

    resetPassword = (body: ApiResetPasswordSchema) => {
        return this.put(`/oauth/reset-password`, body) as Promise<
            IResponseData<boolean>
        >;
    };

    loginWithMicrosoft = (body: ApiLoginWithMicrosoftSchema) => {
        return this.post(
            `/oauth/social-accounts/login-microsoft`,
            body
        ) as Promise<IResponseData<ApiLoginWithMicrosoftResponse>>;
    };
    loginWithGoogle = (body: ApiLoginWithGoogleSchema) => {
        return this.post(
            `/oauth/social-accounts/login-google`,
            body
        ) as Promise<IResponseData<ApiLoginWithGoogleResponse>>;
    };
    validPassword = () => {
        return this.get('/oauth/password-rules') as Promise<
            IResponseData<ApiValidatePasswordResponse>
        >;
    };
}

const authenticateService = new AuthenticateService();

export default authenticateService;
