import { FC, memo } from 'react';
interface ILoadingScreenProps {}

const LoadingScreen: FC<ILoadingScreenProps> = memo(() => {
    return null;
});

LoadingScreen.displayName = 'LoadingScreen';

export default LoadingScreen;
