const messageCodes = {
    SUCCESS: 'Success',
    CONTACT_ADMIN_FOR_SUPPORT:
        'Something went wrong. Please contact admin for support',

    // Login related
    INVALID_USERNAME_OR_PASSWORD:
        'Your username or password is incorrect! Please try again',

    USER_ALREADY_EXISTS:
        'User with this email already exists. Please try again',

    OLD_PASSWORD_NOT_MATCH: 'Current password does not match',
    SUBDOMAIN_WRONG_CONFIG: 'Domain name used, please choose a different name',
    SUBDOMAIN_ALREADY_EXISTS:
        'Domain name already exists, please choose a different name',
    CANNOT_MODIFY_THIS_DATABASE: 'Cannot modify this database',
    USER_NOT_FOUND: 'Your account does not exist!',

    default: 'Something went wrong. Please try again later',
} as {
    [key: string]: string;
};

export default messageCodes;
