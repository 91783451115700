import Loadable from 'react-loadable';
import LoadableLoading from 'route/components/LoadableLoading';
import routeConstants from 'route/routeConstant';

interface CommonRoute {
    name: string;
    path: string;
    authorization?: boolean;
    childRoutes?: Route[];
}

interface RedirectRoute extends CommonRoute {
    redirect: string;
}

interface ComponentRoute extends CommonRoute {
    Component: any;
}

type Route = RedirectRoute | ComponentRoute;

const commonLoadable = (loader: any) =>
    Loadable({
        loader,
        loading: LoadableLoading,
    });

const routes: Array<Route> = [
    {
        name: 'Login',
        path: routeConstants.LOGIN,
        Component: commonLoadable(() => import('views/LoginAndRegister')),
    },
    {
        name: 'Forgot password',
        path: routeConstants.FORGOT_PASSWORD,
        Component: commonLoadable(() => import('views/ForgotPassword')),
    },
    {
        name: 'Reset password',
        path: routeConstants.RESET_PASSWORD,
        Component: commonLoadable(() => import('views/ResetPassword')),
    },
    {
        name: 'MainLayout',
        path: routeConstants.MAIN,
        Component: commonLoadable(() => import('layouts/Main')),
        authorization: true,
        childRoutes: [
            {
                name: 'Main default',
                path: routeConstants.MAIN,
                redirect: routeConstants.DATABASE_MATRIX,
            },
            {
                name: 'List of databases',
                path: routeConstants.DATABASE_MATRIX,
                Component: commonLoadable(() => import('views/Database/List')),
            },
            {
                name: 'Create new database',
                path: routeConstants.DATABASE_CREATE,
                Component: commonLoadable(
                    () => import('views/Database/Create')
                ),
            },
            {
                name: 'Create new success',
                path: routeConstants.DATBASE_DEPLOY,
                Component: commonLoadable(
                    () => import('views/Database/Deploy')
                ),
            },
        ],
    },
    {
        name: '404',
        path: '*',
        Component: commonLoadable(() => import('views/Page/404')),
    },
];

export default routes;
