import { Button } from 'antd';
import { memo } from 'react';
import routeConstants from 'route/routeConstant';
import './error.scss';

const E404 = memo(() => {
    return (
        <div className="error-page">
            <div className="error-page__container">
                {/* <div className="error-page__logo">
                    <LogoCDS />
                </div> */}
                <h1 className="error-page__title">
                    <span>4</span>
                    <span>0</span>
                    <span>4</span>
                </h1>
                <p className="error-page__text">
                    THE PAGE YOU REQUESTED COULD NOT FOUND
                </p>
                <a href={routeConstants.MAIN} className="error-page__link">
                    <Button
                        size="large"
                        type="primary"
                        shape="round"
                        className="error-page__button"
                    >
                        Back Home
                    </Button>
                </a>
            </div>
        </div>
    );
});
E404.displayName = 'E404';

export default E404;
