import { ConfigProvider } from 'antd';
import { memo } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import RouteController from 'route/RouteController';
import store, { persistor } from 'store/Store';
import FontSizeProvider from 'theme/FontSizeProvider';
import ErrorContainer from 'views/Error/ErrorContainer';
import LoadingScreen from 'views/LoadingScreen/LoadingScreen';

const App = memo(() => {
    return (
        <Provider store={store}>
            <PersistGate loading={<LoadingScreen />} persistor={persistor}>
                <ErrorContainer>
                    <FontSizeProvider>
                        <ConfigProvider
                            componentSize="middle"
                            theme={{
                                token: {
                                    wireframe: true,
                                    borderRadius: 0,
                                    colorInfo: '#0c4288',
                                    colorSuccess: '#34ab71',
                                    colorWarning: '#f4c25b',
                                    colorError: '#ff5f73',
                                    colorPrimary: '#0c4288',
                                    colorTextBase: '#111111',
                                    colorBorder: '#d8d8d8',
                                    fontSize: 12,
                                    colorBgLayout: '#eeeeee',
                                    fontSizeHeading2: 24,
                                    colorPrimaryHover: '#04377a',
                                    colorPrimaryBorderHover: '#04377a',
                                    colorPrimaryBorder: '#0c4288',
                                    colorPrimaryTextHover: '#004cd8',
                                    colorPrimaryBg: '#d6e4f2',
                                    colorTextPlaceholder: '#969696',
                                },
                                components: {
                                    Button: {
                                        defaultHoverBg: '#D6E4F2',
                                    },
                                    Input: {
                                        hoverBorderColor: '#4096ff',
                                        activeBorderColor: '#4096ff',
                                        colorPrimaryHover: 'rgb(0, 76, 216)',
                                        colorPrimaryActive: 'rgb(0, 76, 216)',
                                        colorPrimary: 'rgb(0, 76, 216)',
                                    },
                                    Layout: {
                                        fontSize: 12,
                                        headerBg: 'transparent',
                                        headerHeight: 'auto',
                                        headerPadding: '0',
                                        footerPadding: '0',
                                    },
                                    Typography: {
                                        colorLink: 'rgb(0, 76, 216)',
                                        colorLinkActive: 'rgb(12, 66, 136)',
                                        colorLinkHover: 'rgb(12, 66, 136)',
                                        fontSize: 12,
                                        fontSizeHeading1: 32,
                                        fontSizeHeading3: 20,
                                        fontSizeHeading4: 16,
                                        fontSizeHeading5: 14,
                                        lineHeightHeading4: 1.5,
                                        lineHeightHeading1: 1.25,
                                        lineHeightHeading2: 1.333334,
                                        lineHeightHeading3: 1.4,
                                        lineHeightHeading5: 1.428,
                                        lineHeight: 1.333334,
                                        titleMarginBottom: 0,
                                    },
                                    Space: {
                                        paddingXS: 8,
                                        padding: 12,
                                    },
                                    Checkbox: {
                                        fontFamily: 'SVN-Bio Sans',
                                        colorPrimary: 'rgb(0, 76, 216)',
                                        colorPrimaryBorder: 'rgb(0, 76, 216)',
                                    },
                                },
                            }}
                        >
                            <RouteController />
                        </ConfigProvider>
                    </FontSizeProvider>
                </ErrorContainer>
            </PersistGate>
        </Provider>
    );
});
App.displayName = 'App';

export default App;
