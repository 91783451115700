import { forEach, isArray, isObject } from 'lodash';
import { toCamelCase } from 'utils/function/format';

export interface IResponse {
    isSuccess: boolean;
    errorCode: number;
    message: string;
    data: any;
    messageCode: string;
}

export interface IResponseData<T> {
    isSuccess: boolean;
    errorCode: number;
    message: string;
    messageCode: string;
    data: T | null;
}

class ResponseModel implements IResponse {
    isSuccess = false;
    errorCode = 0;
    message = '';
    data = null;
    messageCode = '';
}

export const prepareResponse = (response: any): any => {
    let result = null as any;
    if (isArray(response)) {
        result = response.map((item: any) => prepareResponse(item));
    } else if (isObject(response)) {
        result = {};
        forEach(response, (value: any, key: string) => {
            result[toCamelCase(key)] = prepareResponse(value);
        });
    } else {
        result = response;
    }
    return result;
};

export const getResponse = (responseBody: any, error?: Error): IResponse => {
    let response = new ResponseModel();
    response.isSuccess = responseBody?.error_code === 0;
    response.errorCode = responseBody?.error_code || 0;
    response.message = responseBody?.message || '';
    response.data = prepareResponse(responseBody?.data);
    response.messageCode = responseBody?.message_code || '';
    if (error) {
        response.message = String(error);
    }
    return response;
};
